import React, { useState } from "react";
import AttachmentService from "../service/AttachmentService";

export default function AttachmentView({ item }) {
  const [downloadingAttachmentId, setDownloadingAttachmentId] = useState(null);

  const handleDownloadAttachment = async (attachmentId, attachmentMime) => {
    setDownloadingAttachmentId(attachmentId);

    let url = "";
    try {
      const fileData = await AttachmentService.getAttachment(attachmentId);
      const blob = new Blob([fileData], {
        type: attachmentMime,
      });
      url = window.URL.createObjectURL(blob);
      window.open(url);
    } catch (error) {
      console.error("Error downloading attachment:", error);
    } finally {
      setDownloadingAttachmentId(null);
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <>
      <span className="font-semibold">Attachments:</span>
      <ul className="mt-0 text-lg font-normal tracking-normal">
        {item?.attachments?.map((attachment, index) => (
          <li
            className=" font-normal tracking-normal flex items-center gap-2"
            key={attachment.id}
          >
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                handleDownloadAttachment(attachment.id, attachment.mime);
              }}
              className="text-blue-500"
            >
              {attachment.name}
            </button>
            {downloadingAttachmentId === attachment.id && (
              <div className="inline-block h-4 w-4 border-2 border-t-transparent animate-spin rounded-full border-stone-600"></div>
            )}
          </li>
        ))}
      </ul>
    </>
  );
}
