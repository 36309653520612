import SearchList from "../component/SearchList";
import SearchPanel from "../component/SearchPanel";
import { useFilter } from "../data/useFilter";
import PrintWorks from "../component/PrintWorks";
import AddWorkModal from "../component/AddWorkModal";
import { useState } from "react";

export default function Search() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleFilter = () => {};
  const { filteredData, error, isLoading: loading } = useFilter();
  return (
    <>
      <AddWorkModal isOpen={isModalOpen} onRequestClose={closeModal} />
      <div className="flex flex-col pt-16 md:flex-row">
        <div className="flex-shrink-0 px-4 py-6 md:w-[350px]">
          <SearchPanel onFilter={handleFilter} />
          <div className="flex items-center justify-center gap-4">
            <button
              onClick={openModal}
              className="mt-4 w-1/2 rounded border border-gray-300 bg-slate-100 p-1 text-center font-semibold hover:bg-slate-500 hover:text-white focus:outline-none active:bg-slate-700"
            >
              Add Work
            </button>
          </div>
        </div>

        <div className="md:flex-grow">
          <SearchList
            filteredData={filteredData}
            error={error}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
}
