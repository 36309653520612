// src/data/useCreateWork.js
import { useMutation, useQueryClient } from "@tanstack/react-query";
import workService from "../service/WorkService"; // Adatta il percorso in base alla tua struttura

export function useCreateWork() {
  const queryClient = useQueryClient();
  const { mutate: createWork, isLoading: isCreating } = useMutation({
    mutationFn: async (newWork) => {
      try {
        const response = await workService.create(newWork);
        console.log("API Response:", response); // Log della risposta dell'API
        return response; // Assicurati di restituire la risposta
      } catch (error) {
        console.error("API Error:", error); // Log degli errori
        throw error; // Rilancia l'errore per la gestione
      }
    },
    onSuccess: (newWork) => {
      // Potresti voler fare qualcosa al successo, come invalidare cache o mostrare un messaggio
      console.log("Work added successfully");

      // Aggiorna localStorage con il nuovo work
      const savedData = JSON.parse(localStorage.getItem("searchData")) || [];
      const updatedData = [...savedData, newWork];
      localStorage.setItem("searchData", JSON.stringify(updatedData));

      // Invalida e refetch dei dati
      queryClient.invalidateQueries(["worksData"]);
      // Aggiorna lo stato dei dati locali immediatamente
      queryClient.setQueryData(["worksData"], updatedData);
    },

    onError: (error) => {
      // Gestisci l'errore
      console.error("Error adding work:", error);
    },
  });

  return { isCreating, createWork };
}
