import React from "react";

import { useImage } from "../data/useImage";

export default function SearchItem({ item, index, isLast }) {
  return (
    <li
      style={{
        pageBreakInside: "avoid",
      }}
      className={`mb-10 flex cursor-pointer flex-wrap items-center justify-around rounded-lg border-transparent bg-white px-10 py-4 shadow-md transition-all duration-300 md:flex-nowrap print:mb-0 print:rounded-none print:border print:border-neutral-800 ${index % 6 !== 5 && !isLast ? "print:border-b-0" : ""} print:shadow-none`}
    >
      <SearchItemContent item={item} />
    </li>
  );
}

function SearchItemContent({ item }) {
  return (
    <div className="flex flex-grow items-center">
      <SearchItemPhoto item={item} />
      <div className="min-w-20 flex-grow text-center md:w-1/4 print:w-1/12">
        <p className="text-lg font-medium tracking-tight text-neutral-800">
          Cat:
        </p>
        <p className="">{item.catalogue}</p>
      </div>
      <div className="min-w-20 flex-grow text-center md:w-1/4 print:w-1/6">
        <p className="text-lg font-medium tracking-tight text-neutral-800">
          Artist:
        </p>
        <p>{item.artist?.name}</p>
      </div>
      <div className="min-w-20 flex-grow text-center md:w-1/4 print:w-1/4">
        <p className="px-4 text-lg font-medium tracking-tight text-neutral-800">
          Title:
        </p>
        <p className="px-4">{item.title}</p>
      </div>
      <div className="min-w-20 flex-grow text-center md:w-1/4 print:w-1/6">
        <p className="text-lg font-medium tracking-tight text-neutral-800">
          Location:
        </p>
        <p>{item.location}</p>
      </div>
    </div>
  );
}

function SearchItemPhoto({ item }) {
  const photoId = item.photoIds.find((photo) => photo.primary)?.id;
  const { image, isLoading, error } = useImage(photoId);
  console.log("Error: ", error);
  return (
    <div className="flex h-40 w-40 max-w-full flex-shrink-0 items-center justify-center print:h-32">
      {isLoading && ( // Mostra un indicatore di caricamento se l'immagine è in fase di recupero
        <div className="mr-4 flex h-40 w-40 items-center justify-center rounded-md bg-gray-200">
          <div className="h-8 w-8 animate-spin rounded-full border-2 border-stone-600 border-t-transparent"></div>
        </div>
      )}

      {image && (
        <img
          src={image}
          alt={item.name}
          className="max-h-full max-w-full rounded-md object-contain"
        />
      )}

      {(error || !photoId) && (
        // Mostra un riquadro grigio se l'immagine non è disponibile
        <div className="mr-4 flex h-40 w-40 items-center justify-center rounded-md bg-gray-200">
          <span>No Image Found</span>
        </div>
      )}
    </div>
  );
}
