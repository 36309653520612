import axios from "axios";
import AuthService from "../AuthService";

const API_URL = process.env.REACT_APP_API_URL;

const makeAPIRequest = async (options) => {
  const { method, url, data, responseType } = options;
  try {
    const response = await axios({
      method,
      url: API_URL + url,
      data,
      responseType,
      headers: { Authorization: `${localStorage.getItem("token")}` },
    });
    return response.data;
  } catch (error) {
    if (
      error.response?.status === 401
      // && error.response?.data?.errorCode === "nam_auth_6"
    ) {
      try {
        await AuthService.refreshToken();
        return makeAPIRequest(options);
      } catch (refreshError) {
        throw refreshError;
      }
    } else {
      throw new Error(error.response ? error.response.data.errorCode : error);
    }
  }
};

export default makeAPIRequest;

/* 
 

    const response = await axios({ method, url: API_URL + url, data, headers: { Authorization: `Bearer ${localStorage.getItem("token")}`, ...headers } });
    return response.data;
  } catch (error) {
    // Se la richiesta fallisce per un errore di token scaduto, prova a refresharlo
    if (error.response?.status === 401 && error.response?.data?.errorCode === "nam_auth_6") {
      try {
        // Effettua il refresh del token
        const newToken = await AuthService.refreshToken();

        // Ripeti la richiesta originale con il nuovo token
        options.headers = { ...options.headers, Authorization: `Bearer ${newToken}` };
        return makeAPIRequest(options);
      } catch (refreshError) {
        // Gestisci gli errori durante il refresh del token
        console.error("Errore durante il refresh del token:", refreshError);
        throw refreshError;
      }
    } else {
      // Gestisci gli altri errori di richiesta
      console.error("Errore durante la richiesta:", error);
      throw error;
    }
  }
};

export default makeAPIRequest;

 */
