import { useQueryClient, useMutation } from "@tanstack/react-query";
import WorkService from "../service/WorkService";

export function useEditWorksData() {
  const queryClient = useQueryClient();
  const { mutate: editWork, isLoading: isEditing } = useMutation({
    mutationFn: ({ workId, updatedWorkData }) =>
      WorkService.update(updatedWorkData, workId),

    onSuccess: async (newWork) => {
      // Aggiorna localStorage con il nuovo work
      const savedData = JSON.parse(localStorage.getItem("searchData")) || [];
      const updatedData = savedData?.map((work) =>
        work.id === newWork.id ? newWork : work,
      );
      localStorage.setItem("searchData", JSON.stringify(updatedData));

      // Invalida e refetch dei dati
      queryClient.invalidateQueries(["work", newWork.id]);
      queryClient.invalidateQueries(["worksData"]);
      // Aggiorna lo stato dei dati locali immediatamente
      await queryClient.setQueryData(["worksData"], updatedData);
    },
  });

  return { isEditing, editWork };
}

/* const updateWorkData = await WorkService.update(data, id);
updateWork(updateWorkData);



function updateWork(updatedWorkData) {
    const index = data.findIndex(
      (artwork) => artwork.id === updatedWorkData.id
    );
    // Crea una copia dei dati correnti
    const newData = [...data];
    // Aggiorna l'opera d'arte nei dati
    newData[index] = updatedWorkData;
    // Aggiorna lo stato dei dati
    //setData(newData);
    localStorage.removeItem(`searchData`);
    localStorage.setItem(`searchData`, JSON.stringify(newData));


 */
