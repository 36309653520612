// src/data/useCreateWork.js
import { useMutation, useQueryClient } from "@tanstack/react-query";
import SchoolService from "../service/SchoolService";

export function useCreateSchool() {
  const queryClient = useQueryClient();
  const { mutate: createSchool, isLoading: isCreating } = useMutation({
    mutationFn: async (newSchool) => {
      try {
        const response = await SchoolService.create(newSchool);
        console.log("API Response:", response); // Log della risposta dell'API
        return response; // Assicurati di restituire la risposta
      } catch (error) {
        console.error("API Error:", error); // Log degli errori
        throw error; // Rilancia l'errore per la gestione
      }
    },
    onSuccess: async (data) => {
      //Aggiorna i dati restuiti dal server nel localStorage locale
      const savedData = JSON.parse(localStorage.getItem("schoolsData")) || [];
      const updatedData = [...savedData, data];

      localStorage.setItem("schoolsData", JSON.stringify(updatedData));
      // Invalida e refetch dei dati
      queryClient.invalidateQueries(["schools"]);
    },

    onError: (error) => {
      // Gestisci l'errore
      console.error("Error adding school:", error);
    },
  });

  return { isCreating, createSchool };
}
