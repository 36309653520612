import { useQueryClient, useMutation } from "@tanstack/react-query";
import ImagesService from "../service/ImagesService";

export function useDeleteImage(workId) {
  const queryClient = useQueryClient();
  const deleteImageMutation = useMutation(
    async (imageUrl) => {
      const imageId = queryClient
        .getQueryData(["secondaryImages", workId])
        .find((image) => image.url === imageUrl).id;
      console.log("useDeleteImage", imageId);
      await ImagesService.delete(imageId);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["work", workId]);
        await queryClient.invalidateQueries(["secondaryImages", workId]);
      },
    }
  );

  return {
    deleteImage: deleteImageMutation.mutateAsync,
    isDeleting: deleteImageMutation.isLoading,
  };
}
