// AuthService.js
import makeAPIRequest from "./utils/APIUtils";

const ERROR_MESSAGES = {
  nam_auth_5: "Wrong credentials",
  nam_auth_1: "Generic Error",
  default: "Network Error",
};

const AuthService = {
  login: async (email, password) => {
    try {
      const resp = await makeAPIRequest({
        method: "post",
        url: "/auth/login",
        data: {
          email,
          password,
        },
      });

      const { token, name, lastname } = resp.response;

      //  if (status === 1) {
      // Salva il token nel local storage o nei cookie
      localStorage.setItem("token", token);
      localStorage.setItem("name", name);
      localStorage.setItem("lastname", lastname);
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);

      //  } else {
      //  setError(
      //    "Login failed: " + ERROR_MESSAGES[errorCode] || ERROR_MESSAGES.default
      //  );
    } catch (error) {
      throw new Error(
        "Login failed: " +
          (ERROR_MESSAGES[error.message] || ERROR_MESSAGES.default),
      );
    }
  },
  refreshToken: async () => {
    const email = localStorage.getItem("email");
    const password = localStorage.getItem("password");

    try {
      const resp = await makeAPIRequest({
        method: "post",
        url: "/auth/login",
        data: {
          email,
          password,
        },
      });

      const token = resp.response.token;
      localStorage.setItem("token", token);
    } catch (error) {
      throw new Error("Error refreshing token. Please logout");
    }
  },
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("lastname");
    localStorage.removeItem("email");
    localStorage.removeItem("password");
    //onLogout();
  },

  getActiveUsers: async () => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "get",
        url: "/auth/getsActive",
      });

      return resp.response;
    } catch (error) {
      throw new Error(
        "Fetching Active Users Artist failed: "(
          ERROR_MESSAGES[error.message] || ERROR_MESSAGES.default,
        ),
      );
    }
  },
};

export default AuthService;
