import { useQuery } from "@tanstack/react-query";
import ArtistService from "../service/ArtistService";

export function useArtists() {
  const {
    data: artists,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["artists"],
    queryFn: ArtistService.getAll,
    cacheTime: 1000 * 60 * 60, // Mantieni i dati in cache per 60 minuti
    staleTime: 1000 * 60 * 15, // Mostra i dati in cache per 15 minuti
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      localStorage.setItem("artistData", JSON.stringify(data));
    },
    initialData: () => {
      const savedData = localStorage.getItem("artistData");
      return savedData ? JSON.parse(savedData) : undefined;
    },
    error: (err) => {
      throw new Error(
        "Fatching data failed: " + err.message || "Network Error",
      );
    },
  });

  return {
    artists,
    isLoading,
    error,
  };
}
