// src/components/lists/GenericListItem.js
import React, { useState } from "react";

const UserItem = ({ item, onEdit, onDelete, fields }) => {
  const [isEditing, setIsEditing] = useState(false);
  const roles = ["Admin", "Viewer", "Editor"];

  const handleEditClick = () => {
    setIsEditing((isEditing) => !isEditing);
  };

  const handleSave = (data) => {
    console.log(data);
    setIsEditing(false);
  };

  return (
    <div className="flex flex-wrap items-center justify-center border-b border-gray-200 py-2">
      <>
        <div className="flex flex-1 items-center py-4">
          {fields.map((field) => (
            <div key={field.name} className="h-fit flex-1 px-4 text-center">
              {Array.isArray(item[field.name]) && !isEditing
                ? item[field.name].map((subItem, index) => (
                    <p key={index}>{subItem}</p>
                  ))
                : Array.isArray(item[field.name])
                  ? roles.map((option) => (
                      <div key={option} className="flex items-center">
                        <input
                          type="checkbox"
                          id={`${option}`}
                          value={option}
                          className="rounded border border-gray-300 p-2"
                        />
                        <label htmlFor={`${option}`} className="ml-2">
                          {option}
                        </label>
                      </div>
                    ))
                  : item[field.name]}
            </div>
          ))}
        </div>
        <div className="flex space-x-2">
          <button
            onClick={handleEditClick}
            className="rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-700"
          >
            Reset password
          </button>
          {!isEditing ? (
            <button
              onClick={handleEditClick}
              className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-700"
            >
              Edit Roles
            </button>
          ) : (
            <button
              onClick={handleSave}
              className="rounded bg-green-500 px-4 py-2 text-white hover:bg-green-700"
            >
              Save Roles
            </button>
          )}
          <button
            onClick={() => onDelete(item.id)}
            className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-700"
          >
            Delete
          </button>
        </div>
      </>
    </div>
  );
};

export default UserItem;
