import { useQueryClient, useMutation } from "@tanstack/react-query";
import ImagesService from "../service/ImagesService";

export function useUploadImage({ workId, primary, image, onUploadSuccess }) {
  const queryClient = useQueryClient();

  const uploadImageMutation = useMutation(
    async () => {
      console.log("uploadImageMutation", workId, primary, image);
      const newImageId = await ImagesService.upload({ workId, primary, image });
      return newImageId;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["work", workId]);
        await queryClient.invalidateQueries(["secondaryImages", workId]);
        onUploadSuccess();
      },
    },
  );

  return {
    uploadImage: uploadImageMutation.mutateAsync,
    isUploading: uploadImageMutation.isLoading,
  };
}
