import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
  Outlet,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import Home from "./page/Home";
import LoginForm from "./component/LoginForm";
import Search from "./page/Search";
import Header from "./component/Header";
import WorkDetail from "./page/WorkDetail";
import { SearchProvider } from "./component/SearchContext";
import { Exhibitions } from "./page/Exhibitions";
import { Schools } from "./page/Schools";
import { Artists } from "./page/Artists";
import { Users } from "./page/Users";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes di cache
      cacheTime: 1000 * 60 * 5, // 5 minutes di cache
    },
  },
});

function App() {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("token") != null,
  );

  const handleLogin = () => {
    setLoggedIn(true);
  };

  const handleLogout = () => {
    setLoggedIn(false);
  };

  /* useEffect(() => {
    // Funzione per gestire l'evento di cambiamento nel localStorage
    const handleStorageChange = (event) => {
      // Verifica se il token è stato aggiunto o rimosso
      if (event.key === "token") {
        setLoggedIn(event.newValue != null);
      }
    };

    // Aggiungi i listener per l'evento di aggiunta e rimozione del token
    window.addEventListener("storage", handleStorageChange);

    // Rimuovi i listener quando il componente viene smontato
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
 */

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />

      <Router>
        <div className="App">
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute onLogout={handleLogout} loggedIn={loggedIn} />
              }
            >
              <Route index element={<Home />} />
              <Route path="/search" element={<Search />} />
              <Route path="/work/:id" element={<WorkDetail />} />
              <Route path="/exhibitions" element={<Exhibitions />} />
              <Route path="/schools" element={<Schools />} />
              <Route path="/artists" element={<Artists />} />
              <Route path="/users" element={<Users />} />

              {/* Definisci la route per WorkDetail */}
            </Route>
            <Route
              path="/login"
              element={
                loggedIn ? (
                  <Navigate to="/" replace />
                ) : (
                  <LoginForm onLogin={handleLogin} />
                )
              }
            />
          </Routes>
        </div>
      </Router>
    </QueryClientProvider>
  );
}
export default App;

const PrivateRoute = ({ onLogout, loggedIn }) => {
  return loggedIn ? (
    <>
      <Header onLogout={onLogout}></Header>
      <SearchProvider>
        <Outlet />
      </SearchProvider>
    </>
  ) : (
    <Navigate to="/login" replace />
  );
};
