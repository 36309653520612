// src/components/lists/SchoolList.js
import React, { useState } from "react";
import GenericList from "./GenericList";
import { useEditArtist } from "../../data/useEditArtist";
import { useDeleteArtist } from "../../data/useDeleteArtist";
import { useCreateArtist } from "../../data/useCreateArtist";
import { useUsers } from "../../data/useUsers";
import ItemForm from "../Form/ItemForm";
import ListItem from "./ListItem";
import LoadingSpinner from "../UI/LoadingSpinner";
import ConfirmationModal from "../UI/ConfirmationModal";
import UserForm from "../Form/UserForm";
import UserItem from "./UserItem";

const usersField = [
  { name: "name", label: "Name", required: true },
  { name: "lastname", label: "Lastname", required: true },
  { name: "email", label: "Email", required: true },
  { name: "roles", label: "Roles", required: true },
];

const UsersList = () => {
  const { users, error, isLoading } = useUsers();
  const { editArtist, isEditing } = useEditArtist();
  const { deleteArtist, isDeleting } = useDeleteArtist();
  const { createArtist, isCreating } = useCreateArtist();

  const [isAdding, setIsAdding] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const handleAddClick = () => {
    setIsAdding(true);
  };

  const handleCancelAdd = () => {
    setIsAdding(false);
  };

  const handleSaveNewItem = (newItem) => {
    setIsAdding(false);
  };

  const handleConfirmDelete = () => {
    if (itemToDelete) {
      setItemToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setItemToDelete(null);
  };

  if (isLoading) return <LoadingSpinner />;
  if (error) return <div>Error loading items</div>;

  return (
    <div className="">
      <div className="mx-auto w-fit pb-2">
        <button
          className="rounded border bg-white px-4 py-1"
          onClick={handleAddClick}
        >
          {`Insert New User`}
        </button>
      </div>
      {isAdding && (
        <UserForm
          defaultValues={usersField.reduce(
            (acc, field) => ({ ...acc, [field.name]: "" }),
            {},
          )}
          onSubmit={handleSaveNewItem}
          onCancel={handleCancelAdd}
          fields={usersField}
        />
      )}
      {users.map((item) => (
        <UserItem
          key={item.id}
          item={item}
          onEdit=""
          onDelete={(id) => setItemToDelete(id)}
          fields={usersField}
        />
      ))}

      <ConfirmationModal
        isOpen={!!itemToDelete}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        message={`Are you sure you want to delete this User?`}
      />

      <ConfirmationModal
        isOpen={!!itemToDelete}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        message={`Are you sure you want reset password?`}
      />
    </div>
  );
};

export default UsersList;
