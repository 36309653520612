import makeAPIRequest from "./utils/APIUtils";

const ERROR_MESSAGES = {
  nam_controller_0: "Authorization token is not present",
  nam_auth_6: "Token Expired",
  nam_attachment_1: "Attachment not found",
  default: "Network Error",
};

const AttachmentService = {
  getAttachment: async (idAttachment) => {
    try {
      const resp = await makeAPIRequest({
        method: "get",
        url: "/attachments/get/" + idAttachment,
        responseType: "blob",
      });
      return resp;
    } catch (error) {
      throw new Error(
        "Fetching Attachment failed: " +
          (ERROR_MESSAGES[error.message] || ERROR_MESSAGES.default)
      );
    }
  },
};

export default AttachmentService;
