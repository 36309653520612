import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SearchContext = createContext();

function SearchProvider({ children }) {
  const initialFilters = {
    artist: "",
    catalogue: "",
    category: "",
    exhibitions: "",
    from: "",
    location: "",
    school: "",
    title: "",
    destination: "",
    to: "",
  };
  const [filters, setFilters] = useState(initialFilters);
  const [searchAllParam, setSearchAllParam] = useState("");
  const [currentPage, setCurrentPage] = useState(0); // Nuovo stato per i dati filtrati

  function resetFilters() {
    setFilters(initialFilters);
    setSearchAllParam("");
    setCurrentPage(0);
  }

  function onChangePage(selectedPage) {
    setCurrentPage(selectedPage);
  }

  console.log(filters);

  return (
    <SearchContext.Provider
      value={{
        onSearchAll: setSearchAllParam,
        onFilter: setFilters,
        onResetFilter: resetFilters,
        filters: filters,
        searchAllParam: searchAllParam,
        currentPage: currentPage,
        setCurrentPage: onChangePage,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

function useSearch() {
  const context = useContext(SearchContext);
  return context;
}
export { SearchProvider, useSearch };
