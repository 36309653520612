import { useQuery } from "@tanstack/react-query";
import ImagesService from "../service/ImagesService";
import { useState } from "react";

export function useImage(imageId, immediate = true) {
  const [fetchImage, setFetchImage] = useState(immediate);

  const {
    data: image,
    isLoading,
    error,
  } = useQuery(
    ["images", imageId],
    async () => {
      if (!imageId) {
        return "";
      }
      try {
        const image = await ImagesService.getJpg(imageId);
        if (!image) {
          return "";
        }
        return URL.createObjectURL(image);
      } catch (err) {
        // Gestisce l'errore senza lanciarlo, permettendo a useImage di restituirlo
        console.error("Errore durante il recupero dell'immagine:", err);
        throw err; // Questo errore verrà catturato da react-query e reso disponibile tramite la proprietà `error`
      }
    },
    {
      enabled: fetchImage,
      cacheTime: 1000 * 60 * 60, // Cache per 1 ora
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false, // Ritenta automaticamente il fetch quando la finestra viene messa a fuoco

      onError: (err) => {
        console.error(
          "Errore catturato dalla configurazione di useQuery:",
          err,
        );
      }, // Considera i dati "stale" dopo 5 minuti
    },
  );

  const fetchImageCallback = () => {
    setFetchImage(true);
  };
  console.log("Error: ", error);
  return {
    image,
    isLoading,
    error,
    fetchImage: fetchImageCallback,
  };
}
