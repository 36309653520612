import { useState, useCallback } from "react";
import { useUploadImage } from "../data/useUploadImage";

export function useImageUpload(workId) {
  const [preview, setPreview] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);

  const handleUploadSuccess = useCallback(() => {
    setPreview(null);
    setSelectedFile(null);
    setError(null);
  }, []);

  const { uploadImage, isUploading } = useUploadImage({
    workId,
    primary: false,
    image: selectedFile,
    onUploadSuccess: handleUploadSuccess,
  });

  const handleFileUpload = useCallback((event) => {
    setError(null);
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setError("Il file selezionato non è un'immagine.");
    }
  }, []);

  const onUploadClick = useCallback(async () => {
    if (!selectedFile) return;
    try {
      await uploadImage();
    } catch (error) {
      setError("Error uploading image", error);
    }
  }, [selectedFile, uploadImage]);

  const resetFileInput = useCallback(() => {
    setPreview(null);
    setSelectedFile(null);
    setError(null);
  }, []);

  return {
    preview,
    handleFileUpload,
    onUploadClick,
    isUploading,
    error,
    setError,
    resetFileInput,
  };
}
