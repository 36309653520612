import React, { useRef } from "react";
import { useImageUpload } from "../hooks/useImageUpload";

export function ImageUploadForm({ workId }) {
  const fileInputRef = useRef(null);
  const {
    preview,
    handleFileUpload,
    onUploadClick,
    isUploading,
    error,
    resetFileInput,
  } = useImageUpload(workId);

  const handleUploadAndReset = async () => {
    await onUploadClick();
    resetFileInput();
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  return (
    <div className="flex flex-col items-center">
      {!preview && (
        <>
          <input
            type="file"
            accept="image/*"
            disabled={isUploading}
            ref={fileInputRef}
            onChange={handleFileUpload}
            style={{ display: "none" }}
          />
          <button
            onClick={() => fileInputRef.current.click()}
            disabled={isUploading}
            className={`border border-gray-300 mt-4 px-4 py-1 font-semibold rounded ${
              isUploading
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-slate-100 hover:bg-slate-500 hover:text-white active:bg-slate-700"
            }`}
          >
            {isUploading ? "Uploading..." : "Upload image"}
          </button>
        </>
      )}
      {error && <p className="text-red-500 mt-2">{error}</p>}
      {preview && (
        <div className="mt-4 text-center">
          <h3 className="text-lg font-medium mb-2">
            Anteprima dell'immagine caricata:
          </h3>
          <img
            src={preview}
            alt="Anteprima"
            className="w-24 h-24 object-cover rounded border-2 border-gray-300 mx-auto"
          />
          <button
            type="button"
            onClick={resetFileInput}
            className="mt-4 mx-2 px-4 py-2 text-white rounded bg-red-500"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleUploadAndReset}
            className={`mt-4 px-4 py-2 text-white rounded ${
              isUploading
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-green-500 hover:bg-green-700"
            }`}
            disabled={isUploading}
          >
            {isUploading ? "Uploading..." : "Upload"}
          </button>
        </div>
      )}
    </div>
  );
}
