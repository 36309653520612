import React from "react";

export default function AttachmentEdit() {
  return (
    <>
      <span className="font-semibold">Attachment:</span>
      <input type="file" className="border p-1 flex-grow overflow-x-visible" />
    </>
  );
}
