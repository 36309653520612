import makeAPIRequest from "./utils/APIUtils";

const ERROR_MESSAGES = {
  nam_controller_0: "Internal Server Error",
  nam_auth_6: "Token Expired",
  default: "Network Error",
};

const SchoolService = {
  getAll: async () => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "get",
        url: "/schools/gets",
      });

      return resp.schools;
    } catch (error) {
      throw new Error(
        "Fetching schools failed: " +
          (ERROR_MESSAGES[error.message] || ERROR_MESSAGES.default),
      );
    }
  },
  create: async (schoolData) => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "post",
        url: "/schools/create",
        data: schoolData,
      });

      return resp.school;
    } catch (error) {
      throw new Error(
        "Creating school failed: " +
          (ERROR_MESSAGES[error.message] || ERROR_MESSAGES.default),
      );
    }
  },
  delete: async (schoolId) => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "post",
        url: "/schools/delete",
        data: {
          request: schoolId,
        },
      });
      return resp.response;
    } catch (error) {
      throw new Error(
        "Delete school failed: " +
          (ERROR_MESSAGES[error.message] || ERROR_MESSAGES.default),
      );
    }
  },

  update: async (schoolData) => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "post",
        url: "/schools/update",
        data: { id: schoolData.id, description: schoolData.description },
      });
      console.log(resp.school);
      return resp.school;
    } catch (error) {
      throw new Error(
        "Update failed: " +
          (ERROR_MESSAGES[error.message] || ERROR_MESSAGES.default),
      );
    }
  },
};

export default SchoolService;
