import { useQuery } from "@tanstack/react-query";
import WorkService from "../service/WorkService";
import { useWorksData } from "./useWorksData";

export function useSingleWork(workId) {
  const { works } = useWorksData();

  const {
    data: item,
    isLoading,
    error,
  } = useQuery(
    ["work", workId],
    () => (workId ? WorkService.get(workId) : ""),

    {
      cacheTime: 1000 * 60 * 60, // Cache per 1 ora
      staleTime: 1000 * 60 * 5,
      onError: () => {
        // Se la fetch non va a buon fine, recupera il dato dall'insieme dei dati in cache
        const cachedWork = works.find((work) => work.id === workId);
        return cachedWork;
      },
    },
  );
  return {
    item,
    isLoading,
    error,
  };
}
