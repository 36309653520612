import React, { useState, useEffect } from "react";
import { useRef } from "react";

export default function Dropdown(props) {
  const { getData, label, name, value, onChange, defaultValue } = props;

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const remoteData = await getData();
        setData(remoteData);
        setFilteredData(
          remoteData?.filter((item) => {
            return (item?.description || item?.name || String(item))
              ?.toLowerCase()
              .includes(String(value)?.toLowerCase());
          }),
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [getData, value]);

  useEffect(() => {
    setInputValue(value);
    setFilteredData(
      data?.filter((item) => {
        return (item?.description || item?.name || String(item))
          .toLowerCase()
          .includes(String(value).toLowerCase());
      }),
    );
  }, [data, value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        if (inputRef.current) {
          inputRef.current.blur(); // Perde il focus quando si clicca fuori
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleInputChange = (e) => {
    e.preventDefault();
    const newValue = e.target.value;
    onChange({
      target: {
        name,
        value: newValue,
      },
    });

    setIsOpen(true);
  };

  const handleSelect = (item) => {
    onChange({
      target: {
        name,
        value: item?.name || item?.title || item?.description || item,
      },
    });
    setInputValue(item?.name || item?.title || item?.description || item);
    setIsOpen(false);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setIsOpen(false);
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  return (
    <>
      <label className="w-1/2" onMouseDown={(e) => e.preventDefault()}>
        {label}
      </label>
      <div className="w-1/2" ref={dropdownRef}>
        <input
          id={name}
          name={name}
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
          onKeyDown={handleKeyDown}
          autoComplete="off"
          ref={inputRef}
          className="w-full rounded border border-gray-300 p-1"
          placeholder={defaultValue}
        />
        {isOpen && (
          <ul className="absolute right-0 top-9 z-10 max-h-40 w-[calc(50%-6px)] overflow-y-auto rounded-sm border bg-white p-1">
            {filteredData?.length > 0 ? (
              filteredData.map((item) => (
                <li
                  key={item?.id}
                  className="cursor-pointer p-2 hover:bg-gray-200"
                  onClick={() => handleSelect(item)}
                >
                  {item?.name || item?.title || item?.description || item}
                </li>
              ))
            ) : (
              <li className="p-2 text-gray-700">No results found</li>
            )}
          </ul>
        )}
      </div>
    </>
  );
}
