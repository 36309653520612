import { useQueryClient, useMutation } from "@tanstack/react-query";
import ExhibitionService from "../service/ExhibitionService";

export function useDeleteExhibition() {
  const queryClient = useQueryClient();
  const { mutate: deleteExhibition, isLoading: isDeleting } = useMutation({
    mutationFn: (idExhibition) => ExhibitionService.delete(idExhibition),

    onSuccess: () => {
      queryClient.invalidateQueries(["exhibitions"]);
    },
    onError: (error) => {
      // Gestisci l'errore
      console.error("Error deleting exhibition:", error);
    },
  });

  return { deleteExhibition, isDeleting };
}

/* const updateWorkData = await WorkService.update(data, id);
updateWork(updateWorkData);



function updateWork(updatedWorkData) {
    const index = data.findIndex(
      (artwork) => artwork.id === updatedWorkData.id
    );
    // Crea una copia dei dati correnti
    const newData = [...data];
    // Aggiorna l'opera d'arte nei dati
    newData[index] = updatedWorkData;
    // Aggiorna lo stato dei dati
    //setData(newData);
    localStorage.removeItem(`searchData`);
    localStorage.setItem(`searchData`, JSON.stringify(newData));


 */
