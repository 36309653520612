import { useQuery } from "@tanstack/react-query";
import AuthService from "../service/AuthService";

export function useUsers() {
  const {
    data: users,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["users"],
    queryFn: AuthService.getActiveUsers,
    cacheTime: 1000 * 60 * 60, // Mantieni i dati in cache per 60 minuti
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      localStorage.setItem("usersData", JSON.stringify(data));
    },
    initialData: () => {
      const savedData = localStorage.getItem("usersData");
      return savedData ? JSON.parse(savedData) : undefined;
    },
    error: (err) => {
      throw new Error(
        "Fetching data failed: " + err.message || "Network Error",
      );
    },
  });

  return {
    users,
    isLoading,
    error,
  };
}
